export default {
    data() {
        return {
            //成分多级跳转
            componentList:[
                {
                    url:'/through-component',     //跳转连接
                    text:'按成分查药品',           //按钮名称
                    icon:'el-icon-set-up',
                    params:[                        //跳转传参参数
                        {txt:'code'}
                    ]
                },
                {
                    url:'/ch-medicinal-price',     //跳转连接
                    text:'中药材详情',           //按钮名称
                    icon:'el-icon-set-up',
                    params:[                        //跳转传参参数
                        {comCode:'chineseMedicinalCode'},
                        {comName:'chineseMedicinalName'},
                    ]
                },
                {
                    // url: '/ch-medicinal-price',     //跳转连接
                    title:'成分',
                    text: '加入成分对比',           //按钮名称
                    icon: 'el-icon-set-up',
                    componentContrast:true,      //是否加入对比
                },
            ],
            //成分多级跳转
            indicationList:[
                {
                    url:'/through-indication',     //跳转连接
                    text:'按疾病查药品',           //按钮名称
                    icon:'el-icon-office-building',
                    params:[                        //跳转传参参数
                        {code:'code'},
                        {name:'name'},
                    ]
                },
                {
                    title:'适应症',
                    text: '加入适应症对比',           //按钮名称
                    icon: 'el-icon-office-building',
                    indicationContrast:true,      //是否加入对比
                },
            ]
        }
    },
    methods: {
        setLink(data){
            let row = this.$help.deepClone(data)
            if (row.componentLink) {
                const arr = JSON.parse(row.componentLink)
                arr.sort((orr1,orr2)=> {
                    return orr2.name.length - orr1.name.length
                })
                arr.forEach((j,i) => {
                    row.component = row.component.replace(j.name, `##{${i}}##`)
                })
                row.component = this.setObj(row.component.split('##'),arr)
            }
            if (row.indicationLink) {
                const arr = JSON.parse(row.indicationLink)
                arr.sort((orr1,orr2)=> {
                    return orr2.name.length - orr1.name.length
                })
                arr.forEach((j,i)=> {
                    row.indication = row.indication.replace(j.commonName || j.name, `##{${i}}##`)
                })
                row.indication = this.setObj(row.indication.split('##'),arr)
            }
            return row
        },
        setObj(obj,list){
            return obj.map(orr=>{
                if(orr.indexOf("{")!=-1){
                    const t = orr.replace("{", "").replace("}", "")
                    orr = list[t]
                }
                return orr
            })
        },
        goClassifiedDrugs() {
            const { href } = this.$router.resolve({
                path: '/new/aim_drugs_treat_big',
                query: {
                    bottom:true,
                }
            });
            window.open(href, "_blank");
        },
        goDrProvince(data) {
            this.goPage('/store/dr_province_centralized_procurement', data)
        },
    }
}